<template lang="pug">
v-container
  v-row.mt-10
    v-col(cols="12")
      h5.normal_title_h5 生涯
  v-row.mt-5
    v-col
      h4 平均
  v-row.table_row
    v-col.table_col.table
      table
        thead.top_head
          tr
            sticky-column.bdr2(:offset="0")
            th.bdr(colspan="2")
            th.bdr(colspan="3") 投籃
            th.bdr(colspan="3") 三分
            th.bdr(colspan="3") 罰球
            th.bdr(colspan="3") 籃板
            th(colspan="6")
        thead
          tr.bottom_head
            sticky-column.bdr2(
              style="width: 100px; min-width: 100px",
              :title="''",
              :offset="0"
            )
            th 出賽
            th.bdr 上場時間
            th 命中
            th 出手
            th.bdr 命中率
            th 命中
            th 出手
            th.bdr 命中率
            th 命中
            th 出手
            th.bdr 命中率
            th 進攻
            th 防守
            th.bdr 籃板
            th 助攻
            th 失誤
            th 抄截
            th 阻攻
            th 犯規
            th 得分
        tbody
          tr(v-for="(seasonData, index) in playerSeasonData", :key="index")
            sticky-column.bdl.bdr2(
              style="width: 100px; min-width: 100px",
              :title="'總計'",
              :offset="0"
            )
            td {{ seasonData.info.record_matches }}
            td.bdr {{ getMinutes(seasonData.box.avg_min) }}
            td {{ seasonData.box.avg_fg_m.toFixed(1) }}
            td {{ seasonData.box.avg_fg_a.toFixed(1) }}
            td.bdr {{ (seasonData.box.fg_percent * 100).toFixed(1) }}%
            td {{ seasonData.box.avg_three_pts_m.toFixed(1) }}
            td {{ seasonData.box.avg_three_pts_a.toFixed(1) }}
            td.bdr {{ (seasonData.box.three_pts_percent * 100).toFixed(1) }}%
            td {{ seasonData.box.avg_ft_m.toFixed(1) }}
            td {{ seasonData.box.avg_ft_a.toFixed(1) }}
            td.bdr {{ (seasonData.box.ft_percent * 100).toFixed(1) }}%
            td {{ seasonData.box.avg_off_reb.toFixed(1) }}
            td {{ seasonData.box.avg_def_reb.toFixed(1) }}
            td.bdr {{ seasonData.box.avg_reb.toFixed(1) }}
            td {{ seasonData.box.avg_ast.toFixed(1) }}
            td {{ seasonData.box.avg_to.toFixed(1) }}
            td {{ seasonData.box.avg_stl.toFixed(1) }}
            td {{ seasonData.box.avg_blk.toFixed(1) }}
            td {{ seasonData.box.avg_pf.toFixed(1) }}
            td {{ seasonData.box.avg_pts.toFixed(1) }}

  v-row.mt-5
    v-col
      h4 累計
  v-row.table_row
    v-col.table_col.table
      table
        thead.top_head
          tr
            sticky-column.bdr2(:offset="0")
            th.bdr(colspan="2")
            th.bdr(colspan="3") 投籃
            th.bdr(colspan="3") 三分
            th.bdr(colspan="3") 罰球
            th.bdr(colspan="3") 籃板
            th(colspan="6")
        thead
          tr.bottom_head
            sticky-column.bdr2(
              style="width: 100px; min-width: 100px",
              :title="''",
              :offset="0"
            )
            th 出賽
            th.bdr 上場時間
            th 命中
            th 出手
            th.bdr 命中率
            th 命中
            th 出手
            th.bdr 命中率
            th 命中
            th 出手
            th.bdr 命中率
            th 進攻
            th 防守
            th.bdr 籃板
            th 助攻
            th 失誤
            th 抄截
            th 阻攻
            th 犯規
            th 得分
        tbody
          tr(v-for="(seasonData, index) in playerSeasonData", :key="index")
            sticky-column.bdl.bdr2(
              style="width: 100px; min-width: 100px",
              :title="'總計'",
              :offset="0"
            )
            td {{ seasonData.info.record_matches }}
            td.bdr {{ getMinutes(seasonData.box.min) }}
            td {{ seasonData.box.fg_m }}
            td {{ seasonData.box.fg_a }}
            td.bdr {{ (seasonData.box.fg_percent * 100).toFixed(1) }}%
            td {{ seasonData.box.three_pts_m }}
            td {{ seasonData.box.three_pts_a }}
            td.bdr {{ (seasonData.box.three_pts_percent * 100).toFixed(1) }}%
            td {{ seasonData.box.ft_m }}
            td {{ seasonData.box.ft_a }}
            td.bdr {{ (seasonData.box.ft_percent * 100).toFixed(1) }}%
            td {{ seasonData.box.off_reb }}
            td {{ seasonData.box.def_reb }}
            td.bdr {{ seasonData.box.reb }}
            td {{ seasonData.box.ast }}
            td {{ seasonData.box.to }}
            td {{ seasonData.box.stl }}
            td {{ seasonData.box.blk }}
            td {{ seasonData.box.pf }}
            td {{ seasonData.box.pts }}
  v-row.mt-10
    v-col(cols="12")
      h5.normal_title_h5 生涯年度
  v-row.mt-5
    v-col
      h4 平均
  v-row.table_row
    v-col.table_col.table
      table
        thead.top_head
          tr
            sticky-column(:offset="0")
            sticky-column.bdr2(:offset="100")
            th.bdr(colspan="3")
            th.bdr(colspan="3") 投籃
            th.bdr(colspan="3") 三分
            th.bdr(colspan="3") 罰球
            th.bdr(colspan="3") 籃板
            th(colspan="6")
        thead
          tr.bottom_head
            sticky-column(
              style="width: 100px; min-width: 100px",
              :title="'年度'",
              :offset="0"
            )
            sticky-column.bdr2(
              style="width: 80px; min-width: 80px",
              :title="'隊伍'",
              :offset="100"
            )
            th 例行/季後
            th 出賽
            th.bdr 上場時間
            th 命中
            th 出手
            th.bdr 命中率
            th 命中
            th 出手
            th.bdr 命中率
            th 命中
            th 出手
            th.bdr 命中率
            th 進攻
            th 防守
            th.bdr 籃板
            th 助攻
            th 失誤
            th 抄截
            th 阻攻
            th 犯規
            th 得分
        tbody
          tr(v-for="(seasonData, index) in playerSeasonDateData", :key="index")
            sticky-column.bdl(
              style="width: 100px; min-width: 100px",
              :title="seasonData.info.season_name",
              :offset="0"
            )
            sticky-column.bdr2(
              style="width: 80px; min-width: 80px",
              :title="seasonData.info.team_name",
              :offset="100"
            )
            td {{ seasonData.info.split_info.name }}
            td {{ seasonData.info.record_matches }}
            td.bdr {{ getMinutes(seasonData.box.avg_min) }}
            td {{ seasonData.box.avg_fg_m.toFixed(1) }}
            td {{ seasonData.box.avg_fg_a.toFixed(1) }}
            td.bdr {{ (seasonData.box.fg_percent * 100).toFixed(1) }}%
            td {{ seasonData.box.avg_three_pts_m.toFixed(1) }}
            td {{ seasonData.box.avg_three_pts_a.toFixed(1) }}
            td.bdr {{ (seasonData.box.three_pts_percent * 100).toFixed(1) }}%
            td {{ seasonData.box.avg_ft_m.toFixed(1) }}
            td {{ seasonData.box.avg_ft_a.toFixed(1) }}
            td.bdr {{ (seasonData.box.ft_percent * 100).toFixed(1) }}%
            td {{ seasonData.box.avg_off_reb.toFixed(1) }}
            td {{ seasonData.box.avg_def_reb.toFixed(1) }}
            td.bdr {{ seasonData.box.avg_reb.toFixed(1) }}
            td {{ seasonData.box.avg_ast.toFixed(1) }}
            td {{ seasonData.box.avg_to.toFixed(1) }}
            td {{ seasonData.box.avg_stl.toFixed(1) }}
            td {{ seasonData.box.avg_blk.toFixed(1) }}
            td {{ seasonData.box.avg_pf.toFixed(1) }}
            td {{ seasonData.box.avg_pts.toFixed(1) }}
  v-row.mt-5
    v-col
      h4 累計
  v-row.table_row
    v-col.table_col.table
      table
        thead.top_head
          tr
            sticky-column(:offset="0")
            sticky-column.bdr2(:offset="100")
            th.bdr(colspan="3")
            th.bdr(colspan="3") 投籃
            th.bdr(colspan="3") 三分
            th.bdr(colspan="3") 罰球
            th.bdr(colspan="3") 籃板
            th(colspan="6")
        thead
          tr.bottom_head
            sticky-column(
              style="width: 100px; min-width: 100px",
              :title="'年度'",
              :offset="0"
            )
            sticky-column.bdr2(
              style="width: 80px; min-width: 80px",
              :title="'隊伍'",
              :offset="100"
            )
            th 例行/季後
            th 出賽
            th.bdr 上場時間
            th 命中
            th 出手
            th.bdr 命中率
            th 命中
            th 出手
            th.bdr 命中率
            th 命中
            th 出手
            th.bdr 命中率
            th 進攻
            th 防守
            th.bdr 籃板
            th 助攻
            th 失誤
            th 抄截
            th 阻攻
            th 犯規
            th 得分
        tbody
          tr(v-for="(seasonData, index) in playerSeasonDateData", :key="index")
            sticky-column.bdl(
              style="width: 100px; min-width: 100px",
              :title="seasonData.info.season_name",
              :offset="0"
            )
            sticky-column.bdr2(
              style="width: 80px; min-width: 80px",
              :title="seasonData.info.team_name",
              :offset="100"
            )
            td {{ seasonData.info.split_info.name }}
            td {{ seasonData.info.record_matches }}
            td.bdr {{ getMinutes(seasonData.box.min) }}
            td {{ seasonData.box.fg_m }}
            td {{ seasonData.box.fg_a }}
            td.bdr {{ (seasonData.box.fg_percent * 100).toFixed(1) }}%
            td {{ seasonData.box.three_pts_m }}
            td {{ seasonData.box.three_pts_a }}
            td.bdr {{ (seasonData.box.three_pts_percent * 100).toFixed(1) }}%
            td {{ seasonData.box.ft_m }}
            td {{ seasonData.box.ft_a }}
            td.bdr {{ (seasonData.box.ft_percent * 100).toFixed(1) }}%
            td {{ seasonData.box.off_reb }}
            td {{ seasonData.box.def_reb }}
            td.bdr {{ seasonData.box.reb }}
            td {{ seasonData.box.ast }}
            td {{ seasonData.box.to }}
            td {{ seasonData.box.stl }}
            td {{ seasonData.box.blk }}
            td {{ seasonData.box.pf }}
            td {{ seasonData.box.pts }}
</template>

<script>
import StickyColumn from "@/components/common/Table/StickyColumn";
import { getPlayerSeasonData } from "@/api/player";

export default {
  name: "LeaguePlayerJob",
  components: {
    StickyColumn,
  },
  data() {
    return {
      playerID: this.$route.params.id,
      playerSeasonData: [],
      playerSeasonDateData: [],

      windowWidth: 0,
      head: null,
      stickyNum: 2,
    };
  },
  created() {
    this.getPlayerSeasonData();
    this.getPlayerSeasonDataByDate();
  },
  methods: {
    getPlayerSeasonDataByDate() {
      let temp = {
        history: true,
        part: "info,box",
        player_id: this.playerID,
        split_type: "DATE",
      };
      getPlayerSeasonData(temp).then((response) => {
        this.playerSeasonDateData = response.data;
      });
    },
    getPlayerSeasonData() {
      let temp = {
        history: true,
        part: "info,box",
        player_id: this.playerID,
      };
      getPlayerSeasonData(temp).then((response) => {
        this.playerSeasonData = response.data;
      });
    },
    getMinutes(temp) {
      let min = parseInt(temp / 60);
      let second = (temp % 60).toFixed(0);
      if (second < 10) {
        return min + ":0" + second;
      }
      return min + ":" + second;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
</style>
